import React, { useState, Fragment } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import * as moment from "moment";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/functions";
import { sendEmail, saveForm } from "../../../services/api";
import Toast from "react-bootstrap/Toast";
import privacyFile from "../../../assets/files/INFORMATIVA PRIVACY.pdf";
import pdf from "../../../assets/files/Regolamento OP Bridgestone_Snowit_Def.pdf";

const Basic = () => {
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(null);
  const [bridgeCode, setBridgeCode] = useState(null);
  const [instanceUrl, setInstanceUrl] = useState(0);
  const [loading, setLoading] = useState(false);
  const getTokenSF = firebase.functions().httpsCallable("getTokenJsForce");

  getTokenSF()
    .then(response => {
      setToken(response.data.accessToken);
      setInstanceUrl(response.data.instanceUrl);
    })
    .catch(error => console.error(error));

  const uploadFile = fields => {
    const file = fields.filePDF;
    const fileName = file.name;
    const directory = "pdf";
    const ref = firebase.storage().ref(directory);
    const filePDF = ref.child(fileName);
    filePDF.put(file).then(snapshot => {
      filePDF.getDownloadURL().then(url => {
        const emailBody = `
        <b>Link del file</b>: <a href="${url}" target="_blank">${url}</a><br>
        <b>Codice Cartoncino</b>: ${fields.code}<br>
        <b>Nome</b>: ${fields.firstName}<br>
        <b>Cognome</b>: ${fields.lastName}<br>
        <b>Data di nascita</b>: ${fields.birthdate}<br>
        <b>CAP</b>: ${fields.postCode}<br>
        <b>Email</b>: ${fields.email}<br>
        <b>Pneumatici Acquistati</b>: ${fields.tyresPurchased}<br>
        <b>Informazioni e Privacy</b>: ${fields.optIn}<br>
        <b>Ricezione Informazione per analisi mercato</b>: ${fields.dataProcessingOptIn}<br>`;
        sendEmail({
          file: url,
          email: "bridgestone@snowitapp.com",
          emailBody,
          subject: "Pratica da lavorare: riscatto coupon Bridgestone"
        });
      });
    });
  };

  return (
    <div>
      <p>
        Grazie per aver acquistato pneumatici Bridgestone e Firestone! Compila
        il form con i tuoi dati, inserisci il codice univoco che hai ricevuto
        nella lettera consegnata dal rivenditore Bridgestone e ricordati di
        caricare la fattura o lo scontrino di acquisto dei pneumatici. Entro 48h
        riceverai all’indirizzo email da te indicato il VOUCHER SNOWIT
        EXPERIENCE che potrai spendere sul sito{" "}
        <a href="https://www.snowitexperience.com" target="_black">
          www.snowitexperience.com{" "}
        </a>
      </p>
      <p>Cosa aspetti?</p>
      <p>
        <strong>Compila subito il form!</strong>
      </p>
      <Formik
        initialValues={{
          code: "",
          firstName: "",
          lastName: "",
          postCode: "",
          email: "",
          repeatEmail: "",
          birthdate: "",
          tyresPurchased: null,
          filePDF: null,
          optIn: false,
          dataProcessingOptIn: false,
          privacy: false
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required("Il codice è obbligatorio"),
          firstName: Yup.string().required("Il nome è obbligatorio"),
          lastName: Yup.string().required("Il cognome è obbligatorio"),
          postCode: Yup.string().required("Il CAP è obbligatorio"),
          email: Yup.string()
            .email("Email è invalida")
            .required("Email è obbligatoria"),
          repeatEmail: Yup.string()
            .oneOf([Yup.ref("email")], "Email non coincidono")
            .required("Email è obbligatoria"),
          /* phone: Yup.number()
            .nullable()
            .notRequired()
            .typeError("Il numero di telefono deve essere numerico"),*/
          birthdate: Yup.date()
            .nullable()
            .required("La data di nascita è obbligatoria")
            .min(
              new Date(1900, 0, 1),
              "La data di nascita deve essere maggiore del 01/01/1900"
            )
            .typeError("La data deve essere nel formato corretto"),
          tyresPurchased: Yup.string()
            .required("La scelta dei pneumatici è obbligatoria")
            .typeError("La scelta dei pneumatici è obbligatoria"),
          filePDF: Yup.mixed().required("Il file PDF è obbligatorio"),
          privacy: Yup.bool().oneOf(
            [true],
            "Accettare i termini del regolamento e di presa visione dell'informativa privacy "
          )
        })}
        onSubmit={fields => {
          console.log("fields", fields);

          setBridgeCode(fields.code);
          setLoading(true);
          const body = {
            firstName: fields.firstName,
            lastName: fields.lastName,
            email: fields.email,
            birthdate: moment(fields.birthdate).format("DD-MM-YYYY"),

            language: "Italian",
            segments: "Car & SUV/Van",
            registrationDate: moment().format("DD-MM-YYYY"),
            registrationSource: "Campaign",
            country: "Italy",
            campaignID: "BS-CAR&SUV/VAN-PROMOZIONESNOWIT-12/2019",
            internalCampaignOrigin: "BS-CAR&SUV/VAN-PROMOZIONESNOWIT-12/2019",
            externalCampaignOrigin: "2019 - Promozione Snowit",
            consents: [
              {
                brand: "Bridgestone",
                optIn: fields.optIn,
                dataProcessingOptIn: fields.dataProcessingOptIn,
                consentInfo: [
                  {
                    consentType: "Brand",
                    enabled: fields.optIn
                  },
                  {
                    consentType: "Product",
                    enabled: fields.optIn
                  },
                  {
                    consentType: "Event",
                    enabled: fields.optIn
                  },
                  {
                    consentType: "Competition",
                    enabled: fields.optIn
                  }
                ]
              }
            ]
          };

          console.log(instanceUrl, token, body);
          saveForm(instanceUrl, token, body)
            .then(res => {
              console.log("res", res);
              setShow(true);
              setLoading(false);
            })
            .catch(e => {
              console.log("errore", e);
            });

          if (fields.filePDF) uploadFile(fields);
        }}
      >
        {props => (
          <Fragment>
            <Form>
              <div className="form-group">
                <label htmlFor="code">
                  Codice univoco stampato sul cartoncino ricevuto dal tuo
                  rivenditore*
                </label>
                <Field
                  name="code"
                  type="text"
                  className={
                    "form-control" +
                    (props.errors.code && props.touched.code
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="code"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="firstName">Nome*</label>
                <Field
                  name="firstName"
                  type="text"
                  className={
                    "form-control" +
                    (props.errors.firstName && props.touched.firstName
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Cognome*</label>
                <Field
                  name="lastName"
                  type="text"
                  className={
                    "form-control" +
                    (props.errors.lastName && props.touched.lastName
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="birthday">Data di nascita*</label>
                <Field
                  name="birthdate"
                  type="date"
                  className={
                    "form-control" +
                    (props.errors.birthdate && props.touched.birthdate
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="birthdate"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="birthday">CAP di Residenza*</label>
                <Field
                  name="postCode"
                  type="text"
                  className={
                    "form-control" +
                    (props.errors.postCode && props.touched.postCode
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="postCode"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <Field
                  name="email"
                  type="text"
                  className={
                    "form-control" +
                    (props.errors.email && props.touched.email
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Ripeti Email*</label>
                <Field
                  name="repeatEmail"
                  type="text"
                  className={
                    "form-control" +
                    (props.errors.repeatEmail && props.touched.repeatEmail
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="repeatEmail"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="tyresPurchased">Pneumatici Acquistati*</label>
                <Field
                  as="select"
                  name="tyresPurchased"
                  className={
                    "form-control" +
                    (props.errors.tyresPurchased && props.touched.tyresPurchased
                      ? " is-invalid"
                      : "")
                  }
                >
                  <option value="">Seleziona</option>
                  <option value="ACQUISTO PNEUMATICI INVERNALI">
                    ACQUISTO PNEUMATICI INVERNALI
                  </option>
                  <option value="PRENOTAZIONE PNEUMATICI ESTIVI">
                    PRENOTAZIONE PNEUMATICI ESTIVI
                  </option>
                </Field>
                <ErrorMessage
                  name="tyresPurchased"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label htmlFor="file">
                  Carica fattura/scontrino di acquisto*
                </label>
                <input
                  name="filePDF"
                  type="file"
                  onChange={event => {
                    props.setFieldValue(
                      "filePDF",
                      event.currentTarget.files[0]
                    );
                  }}
                  className={
                    "form-control" +
                    (props.errors.filePDF && props.touched.filePDF
                      ? " is-invalid"
                      : "")
                  }
                ></input>
                <ErrorMessage
                  name="filePDF"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <p>
                  <i>* i campi contrassegnati sono obbligatori</i>
                </p>
              </div>
              <div className="form-group form-check">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  id="optInCheck"
                  name="optIn"
                ></Field>
                <label className="form-check-label" htmlFor="optInCheck">
                  Sí, autorizzo Bridgestone Europe NV/SA a inviarmi informazioni
                  future relative a concorsi, eventi, aggiornamenti sui
                  prodotti, sul brand e sui servizi offerti dal Gruppo
                  Bridgestone. Dichiaro di aver letto l’informativa sulla
                  privacy e accetto che i miei dati personali forniti siano
                  trattati per gli scopi di marketing generico.
                </label>
              </div>
              <div className="form-group form-check">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  id="dataProcessingOptInCheck"
                  name="dataProcessingOptIn"
                ></Field>
                <label
                  className="form-check-label"
                  htmlFor="dataProcessingOptInCheck"
                >
                  Sì, desidero ricevere comunicazioni per realizzare ricerche di
                  mercato e analisi sulle modalità e/o propensioni al consumo
                  con creazione di profili individuali e/o di gruppo al fine di
                  consentire alla Società di inviarmi in futuro offerte
                  personalizzate
                </label>
              </div>
              <div className="form-group form-check">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  id="privacy"
                  name="privacy"
                  className={
                    "input-check-fallback " +
                    (props.errors.privacy && props.touched.privacy
                      ? "form-control-fallback form-control is-invalid"
                      : "")
                  }
                ></Field>
                <label className="form-check-label" htmlFor="privacy">
                  Dichiaro di aver letto e di accettare integralmente il{" "}
                  <a href={pdf} target="_blank">
                    regolamento dell’operazione
                  </a>{" "}
                  a premi e di aver preso visione dell’
                  <a href={privacyFile} target="_blank">
                    informativa Privacy
                  </a>{" "}
                  al fine della partecipazione*
                </label>
                <ErrorMessage
                  name="privacy"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                {loading && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                {!loading && (
                  <Fragment>
                    {" "}
                    <button type="submit" className="btn btn-primary mr-2">
                      Invia
                    </button>
                    <button type="reset" className="btn btn-secondary">
                      Reset
                    </button>
                  </Fragment>
                )}
              </div>
            </Form>
            {show}
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={6000}
              autohide
            >
              <Toast.Body>Registrazione avvenuta con successo</Toast.Body>
            </Toast>
          </Fragment>
        )}
      </Formik>
    </div>
  );
};

export default Basic;
