import React from "react";
import styled from "styled-components";
const ContactTitle = styled.h3`
  font-size: 32px;
  padding-top: 60px;
  padding-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
`;

const ContactParagraph = styled.p`
  text-align: center;
  padding-bottom: 60px;
  font-size: 14px;
`;

const Contact = () => {
  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ContactTitle>Contatti</ContactTitle>
          </div>
          <div className="col-12 text-center">
            <ContactParagraph>
              Per informazioni e necessità potete rivolgervi alla Segreteria
              contattandola al numero +39 0287177184,
              <p>
                oppure scrivendo una e-mail all’indirizzo{" "}
                <a
                  className="text-danger"
                  href="mailto:bridgestone@snowitapp.com"
                >
                  bridgestone@snowitapp.com
                </a>
                .
              </p>
            </ContactParagraph>
          </div>
          {/*<div className="col-12 text-center">
            <p>
              <FontAwesomeIcon
                icon={faUser}
                className="text-danger mr-2"
                size="lg"
              />
              0287177184
            </p>
            <p>
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-danger"
                size="lg"
              />
              <a
                className="text-danger ml-2"
                href="mailto:bridgestone@snowitapp.com"
              >
                bridgestone@snowitapp.com
              </a>
            </p>
  </div>*/}
        </div>
      </div>
    </section>
  );
};

export default Contact;
