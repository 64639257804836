import React, { useEffect, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import AOS from "aos";
import $ from "jquery";

import "aos/dist/aos.css";
import "./assets/styles/main.scss";

import HomeContainer from "./containers/HomeContainer";
import RegistrationContainer from "./containers/RegistrationContainer";

const App = () => {
  useEffect(() => {
    AOS.init({ once: true });

    let navElement = $("nav");

    $(function() {
      $(window).scrollTop() > navElement.innerHeight()
        ? navElement.addClass("sticky")
        : navElement.removeClass("sticky");
    });
    $(window).on("scroll", function() {
      $(window).scrollTop() > navElement.innerHeight()
        ? navElement.addClass("sticky")
        : navElement.removeClass("sticky");
    });
  });

  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={HomeContainer}></Route>
        <Route path="/home" component={HomeContainer}></Route>
        <Route path="/registration" component={RegistrationContainer}></Route>
      </Switch>
    </Fragment>
  );
};

export default App;
