/*
Client key: 3MVG95NPsF2gwOiNl3qMkz4TuFkARylkGvb8KCtMMEfFY4vrz2UrNpVcxj5Xz4.TL61wWyBo6WBoBgU7au4S_
Client secret: 0FA3894BF61F971E5EAD9D188A29CFCAA7F3FB8ACF9C54A01FFF9056A574BDB1
SF username: bridgestone@snowitapp.com 
*/
const config = {
  firebaseConfig: {
    apiKey: "AIzaSyDC17kR6NY0nUkM9x-ZO2_36bQkPK4w3LU",
    authDomain: "bridgestone-landing-staging.firebaseapp.com",
    databaseURL: "https://bridgestone-landing-staging.firebaseio.com",
    projectId: "bridgestone-landing-staging",
    storageBucket: "bridgestone-landing-staging.appspot.com",
    messagingSenderId: "828731184925",
    appId: "1:828731184925:web:355491413e06f3520a73f4"
  },
  bridgestoneApiPath: "https://login.salesforce.com/services", // "https://test.salesforce.com/services/",
  tokenParams: {
    grant_type: "password",
    client_id:
      "3MVG95NPsF2gwOiNl3qMkz4TuFkARylkGvb8KCtMMEfFY4vrz2UrNpVcxj5Xz4.TL61wWyBo6WBoBgU7au4S_",
    client_secret:
      "0FA3894BF61F971E5EAD9D188A29CFCAA7F3FB8ACF9C54A01FFF9056A574BDB1",
    username: "bridgestone@snowitapp.com",
    password: "r2nuwJn395dAMOQrAsmXrxc6R2uQ46Hxqq"
  }
};

/*
    client_id:
      "3MVG92H4TjwUcLlLeyg6P0W5DR8qzyEc9TZqgg_PXUTbNXdfJb2WSFJgDUVh76yTofyzkAcQJPb32AeINzF4E",
    client_secret:
      "1A0EF72B65D816DC29A4BF9688365158D3978A16DFCD6858093DAF7BED5BB84B",
    username: "api@bridgestoneemea.com.dev",
    password: "Rkh7MnRK6HSgVrUSiPPQcFUzjvoNMBOGpa0WPQK"
*/
export default config;
