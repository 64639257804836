import React, { Fragment } from "react";
import Header from "./Header";
import Home from "./Home";
import Reseller from "./Reseller";
import Contact from "./Contact";
import Rules from "./Rules";
import Footer from "./Footer";

const HomeContainer = () => {
  return (
    <Fragment>
      <Header isRegistration={false}></Header>
      <main>
        <Home></Home>
        <Reseller></Reseller>
        <Contact></Contact>
        <Rules></Rules>
        <Footer></Footer>
      </main>
    </Fragment>
  );
};

export default HomeContainer;
