import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import config from "./utils/config";
import firebase from "firebase/app";
import "firebase/app";

import * as serviceWorker from "./serviceWorker";

const firebaseConfig = config.firebaseConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // var functions = firebase.app().functions();
  // console.log("functions", functions);
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
