import React from "react";

import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Arrow from "../components/Icons/Arrow";
import bgImg from "../assets/img/background-desk.jpg";
import bgImgMobile from "../assets/img/background-mobile.jpg";
import promoWinterImg from "../assets/img/promo-winter.png";
import promoWinterMobileImg from "../assets/img/promo-winter-mobile.png";
import logoSnowBridg from "../assets/img/logo-snow-bridg_new.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { sendEmail } from "../services/api";

const Section = styled.section`
  position: relative;
  padding-top: 20vh;
  padding-bottom: 288px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url(${bgImg});
  text-align: left;
  padding-top: 100px;
  height: 100vh;

  &:after,
  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    bottom: -1px;
    border-bottom: 10px solid #fff;
    z-index: 2;
  }

  &:before {
    left: 0;
    border-right: 12px solid transparent;
    border-left: calc(50vw - 12px) solid #fff;
  }

  &:after {
    right: 0;
    border-left: 12px solid transparent;
    border-right: calc(50vw - 12px) solid #fff;
  }

  @media (min-width: 992px) {
    padding-left: 110px;
    padding-right: 0;
    padding-top: 20vh;
    padding-bottom: 111px;
  }

  @media (max-width: 576px) {
    padding-top: 470px;
    background-image: url(${bgImgMobile});
    background-attachment: initial;
  }

  .container {
    z-index: 1;
    user-select: none;
    cursor: default;
  }
`;

const BgOverlay = styled.div`
  background: linear-gradient(33deg, #8400ff, #413bff);
  opacity: 0.9;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;

  @media (min-width: 992px) {
    background-image: linear-gradient(62deg, #8400ff, #413bff);
  }
`;

const ContainerBox = styled.div`
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
`;

const HomeTitle = styled.h1`
  font-weight: bold;
  text-align: left;
  color: #000;
  font-size: 20px;
  line-height: 1.55;
  margin-bottom: 23px;

  img {
    width: 350px;
    height: auto;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: white;
    margin-bottom: 0;
    img {
      width: 300px;
      height: auto;
    }
  }

  @media (max-width: 575px) {
    text-align: center !important;
    padding: 0;
    margin-bottom: 0;
    img {
      width: 260px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 1.39;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    font-size: 25px;
    img {
      width: 500px;
      height: auto;
    }
  }
`;

const SubTitle = styled.h5`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.83;
  text-align: left;
  color: #000000;

  @media (min-width: 992px) {
    max-width: 500px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    color: white;
    margin-bottom: 0;
  }

  @media (max-width: 575px) {
    font-size: 21px;
    text-align: center;
    color: white;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    font-size: 40px;
  }
`;

const DescriptionBox = styled.p`
  max-width: 22rem;
  text-align: justify;

  @media (max-width: 576px) {
    color: white;
    padding-left: 25px;
    padding-right: 25px;

    font-size: 14px;
  }

  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1200px) {
    max-width: 30rem;
  }
`;

const Box = styled.div`
  width: 100%;
  height: auto;
  margin-left: 0;
  margin-right: auto;
  position: relative;
  text-align: left;
  button {
    width: 280px;
    padding: 12px;
    background-color: #009fe3;
    border-color: #009fe3;
    font-size: 15px;
  }

  @media (max-width: 576px) {
    position: relative;
    text-align: center;
    bottom: 0;
    left: auto;
    right: auto;
  }
`;

const BoxBanners = styled.div`
  position: absolute;
  bottom: 30px;
  right: 80px;
  img {
    width: 300px;
  }

  @media (max-width: 576px) {
    position: relative;
    text-align: center;
    bottom: 20px;
    left: auto;
    right: auto;
  }

  @media (max-width: 575px) {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
  }

  @media (min-width: 1200px) {
    img {
      width: 500px;
    }
  }
`;

const Home = () => {
  const goToRegistration = () => {
    window.location.href = `${window.location.origin}/registration`;
  };
  return (
    <Section id="home">
      <Container fluid={true}>
        <div className="row">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <ContainerBox>
              <HomeTitle data-aos="zoom-in">
                <img
                  src={promoWinterImg}
                  className="d-none d-sm-block"
                  alt="Promo Winter"
                ></img>
                <img
                  src={promoWinterMobileImg}
                  className="d-block d-sm-none"
                  alt="Promo Winter"
                ></img>
              </HomeTitle>
              <SubTitle
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                Dal 16/12/19 al 31/01/20
              </SubTitle>
              <DescriptionBox
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                Se acquisti pneumatici invernali o prenoti pneumatici estivi dei
                marchi Bridgestone - Firestone a partire da 17'' ricevi{" "}
                <strong>in omaggio</strong> un Voucher Experience fino a{" "}
                <strong>50 euro</strong>!
              </DescriptionBox>
              <Box
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="800"
              >
                {/*<Arrow
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="1200"
            />*/}
                {
                  <Button
                    size="md"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    data-aos-delay="1200"
                    onClick={goToRegistration}
                    to="/registration"
                  >
                    Richiedi Voucher Snowit Experience
                  </Button>
                }
              </Box>
            </ContainerBox>
          </div>
        </div>
      </Container>
      <BoxBanners>
        <img className="d-none d-sm-block" src={logoSnowBridg} alt=""></img>
      </BoxBanners>
    </Section>
  );
};

export default Home;
