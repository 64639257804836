const resellersObj = [
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "280871",
          "RAGIONESOCIALE": "CATANIA GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA CIALONA s.n.c.",
          "CAP": "91028",
          "LOCALITA": "PARTANNA",
          "REGIONE": "TP",
          "REGIONNAME": "Trapani",
          "TEL1": "092449743"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "263259",
          "RAGIONESOCIALE": "SASSI GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA BARELLA, 820",
          "CAP": "41058",
          "LOCALITA": "VIGNOLA",
          "REGIONE": "MO",
          "REGIONNAME": "Modena",
          "TEL1": "059769828"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "196953",
          "RAGIONESOCIALE": "APPIA GOMME DI SCHIAVON GIANCARLO E",
          "CHANNEL": "P1",
          "VIA": "VIA CARRARA 12/A - LOC.TOR TRE PONTI",
          "CAP": "04013",
          "LOCALITA": "LATINA",
          "REGIONE": "LT",
          "REGIONNAME": "Latina",
          "TEL1": "0773630522"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197127",
          "RAGIONESOCIALE": "MICELI CARMELO",
          "CHANNEL": "P1",
          "VIA": "VIA PONTINA 391",
          "CAP": "00128",
          "LOCALITA": "ROMA - SPINACETO",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "065085959"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197313",
          "RAGIONESOCIALE": "CIOTTI GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA CARPINETANA NORD SNC",
          "CAP": "00034",
          "LOCALITA": "COLLEFERRO",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0697232037"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "298986",
          "RAGIONESOCIALE": "STAGNI GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA ACQUE ALTE, 160",
          "CAP": "04010",
          "LOCALITA": "BORGO PIAVE",
          "REGIONE": "LT",
          "REGIONNAME": "Latina",
          "TEL1": "0773489223"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "339612",
          "RAGIONESOCIALE": "TYRES E CAR 2016 di FRANCESCO DEL S",
          "CHANNEL": "P1",
          "VIA": "VIA CASILINA snc",
          "CAP": "03032",
          "LOCALITA": "ARCE",
          "REGIONE": "FR",
          "REGIONNAME": "Frosinone",
          "TEL1": "0776523522"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "371366",
          "RAGIONESOCIALE": "FIORINI GOMME di FIORINI ADRIANO",
          "CHANNEL": "P1",
          "VIA": "S.P.SANTA CECILIA, 102",
          "CAP": "00196",
          "LOCALITA": "TECCHIENA",
          "REGIONE": "FR",
          "REGIONNAME": "Frosinone",
          "TEL1": "0775408216"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "381117",
          "RAGIONESOCIALE": "VM PNEUMATICI SNC",
          "CHANNEL": "P1",
          "VIA": "VIA ARISTIDE CARABELLI, 60/66",
          "CAP": "00121",
          "LOCALITA": "OSTIA LIDO ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0664670236"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "219811",
          "RAGIONESOCIALE": "MARINI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA ANTICOLANA 40",
          "CAP": "03012",
          "LOCALITA": "ANAGNI",
          "REGIONE": "FR",
          "REGIONNAME": "Frosinone",
          "TEL1": "0775776011"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "223665",
          "RAGIONESOCIALE": "IRGOM SRL",
          "CHANNEL": "B2",
          "VIA": "VIA ANIME SANTE 118",
          "CAP": "03023",
          "LOCALITA": "CECCANO",
          "REGIONE": "FR",
          "REGIONNAME": "Frosinone",
          "TEL1": "0775602201"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "277163",
          "RAGIONESOCIALE": "PNEUS SERVICE SRL",
          "CHANNEL": "B2",
          "VIA": "VIA PONTE GAGLIARDO, 81",
          "CAP": "04022",
          "LOCALITA": "FONDI",
          "REGIONE": "LT",
          "REGIONNAME": "Latina",
          "TEL1": "0771900426"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "280220",
          "RAGIONESOCIALE": "ANTONELLI GOMME di ANTONELLI SAMUEL",
          "CHANNEL": "B2",
          "VIA": "VIA REATINA, 35",
          "CAP": "67068",
          "LOCALITA": "CAPPELLE DEI MARSI - SCURSOLA MARSICANA",
          "REGIONE": "AQ",
          "REGIONNAME": "L'Aquila",
          "TEL1": "086326555"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "318188",
          "RAGIONESOCIALE": "CLEMENTE GOMME di CLEMENTE SCIPIONI",
          "CHANNEL": "B2",
          "VIA": "VIA PRENESTINA NUOVA, 86",
          "CAP": "00036",
          "LOCALITA": "PALESTRINA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0695270856"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "364933",
          "RAGIONESOCIALE": "CAR SERVICE CASTELLI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA DI VALLERICCIA, 67",
          "CAP": "00072",
          "LOCALITA": "ARICCIA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "069341014"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "336127",
          "RAGIONESOCIALE": "NUOVA PROVVEDI SNC",
          "CHANNEL": "P1",
          "VIA": "LOC. FOSCI - VIA UMBRIA 8",
          "CAP": "53036",
          "LOCALITA": "POGGIBONSI",
          "REGIONE": "SI",
          "REGIONNAME": "Siena",
          "TEL1": "0577936902"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197265",
          "RAGIONESOCIALE": "FABBRIZZI SRL",
          "CHANNEL": "B2",
          "VIA": "STRADA PER PIENZA, 16",
          "CAP": "53045",
          "LOCALITA": "MONTEPULCIANO",
          "REGIONE": "SI",
          "REGIONNAME": "Siena",
          "TEL1": "0578757648"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "200224",
          "RAGIONESOCIALE": "NORI GIANNI",
          "CHANNEL": "P1",
          "VIA": "VIA DEI SOMMOZZATORI 39 41 43",
          "CAP": "00143",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0654280176"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "200248",
          "RAGIONESOCIALE": "GIANNI MASSIMO",
          "CHANNEL": "P1",
          "VIA": "VIA N.S. DI LOURDES 9/11",
          "CAP": "00167",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "066622597"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "203124",
          "RAGIONESOCIALE": "PARISI DOMENICO",
          "CHANNEL": "P1",
          "VIA": "VIA OSTIENSE 259-261",
          "CAP": "00146",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "065757546"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "316863",
          "RAGIONESOCIALE": "PROCACCINI GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "CIRCONVALLAZIONE GIANICOLENSE 348",
          "CAP": "00152",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "065826391"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "337616",
          "RAGIONESOCIALE": "LUCCARINI SRL",
          "CHANNEL": "P1",
          "VIA": "Via Giovan Battista Molinelli, 78/80",
          "CAP": "00166",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0665771676"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "347767",
          "RAGIONESOCIALE": "AURELIA CAR SERVICE SRL",
          "CHANNEL": "P1",
          "VIA": "VIA AURELIA, 557/A",
          "CAP": "00165",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0666414015"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "370934",
          "RAGIONESOCIALE": "MARVAL GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA CASAL DEL MARMO 282 A/B",
          "CAP": "00135",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "063092442"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "371361",
          "RAGIONESOCIALE": "ANGELICO GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA FLAMINIA, 437/439",
          "CAP": "00196",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "063092442"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "207788",
          "RAGIONESOCIALE": "MODAFFERI SANTINO",
          "CHANNEL": "B2",
          "VIA": "VIA RADICOFANI 71",
          "CAP": "00138",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "068800902"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "280078",
          "RAGIONESOCIALE": "ESQUILINO GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA PETRARCA 12",
          "CAP": "00185",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0670497930"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "287899",
          "RAGIONESOCIALE": "LIONETTI GOMME SRL CON SOCIO UNICO",
          "CHANNEL": "B2",
          "VIA": "VIA PORTUENSE, 94F/G/H/I",
          "CAP": "00153",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "065818213"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "363992",
          "RAGIONESOCIALE": "D.N. NON SOLO PNEUMATICI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA DELLA BADIA DI CAVA, 92",
          "CAP": "00142",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "065405746"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "336160",
          "RAGIONESOCIALE": "B&G PNEUMATICI DI ELISABETTA QUERCE",
          "CHANNEL": "P1",
          "VIA": "VIA SAN GIOVANNI IN ARGENTELLA, 109",
          "CAP": "00131",
          "LOCALITA": "ROMA",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0641405722"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "372781",
          "RAGIONESOCIALE": "COSEA SRL",
          "CHANNEL": "P1",
          "VIA": "VIA SALARIA PER L AQUILA KM 90,8",
          "CAP": "02010",
          "LOCALITA": "CITTADUCALE",
          "REGIONE": "RI",
          "REGIONNAME": "Rieti",
          "TEL1": "0756605068"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "211973",
          "RAGIONESOCIALE": "PIT STOP S.R.L.",
          "CHANNEL": "B2",
          "VIA": "VIA C. FERRARI, 39",
          "CAP": "00012",
          "LOCALITA": "GUIDONIA MONTECELLO",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "0774345058"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "250593",
          "RAGIONESOCIALE": "FEROCI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA CAGLIARI, 18/20/22/024",
          "CAP": "00055",
          "LOCALITA": "LADISPOLI",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "069910237"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "276996",
          "RAGIONESOCIALE": "AUTOGOMME GROUP SRL",
          "CHANNEL": "B2",
          "VIA": "VIA SALARIA PER L AQUILA, 80",
          "CAP": "02100",
          "LOCALITA": "RIETI",
          "REGIONE": "RI",
          "REGIONNAME": "Rieti",
          "TEL1": "0746271687"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "333477",
          "RAGIONESOCIALE": "CECCHETTI ALESSANDRO",
          "CHANNEL": "B2",
          "VIA": "Via G. Di Vittorio, 43",
          "CAP": "00015",
          "LOCALITA": "MONTEROTONDO",
          "REGIONE": "RM",
          "REGIONNAME": "Roma",
          "TEL1": "06-9061650"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197321",
          "RAGIONESOCIALE": "SABLONE LIBERATO SNC",
          "CHANNEL": "P1",
          "VIA": "VIA MARE ADRIATICO, 77",
          "CAP": "65010",
          "LOCALITA": "S. TERESA DI SPOLTORE",
          "REGIONE": "PE",
          "REGIONNAME": "Pescara",
          "TEL1": "0854971847"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "300438",
          "RAGIONESOCIALE": "PNEUSMARCHE SRL",
          "CHANNEL": "P1",
          "VIA": "STRADA CARRARECCIA, 35",
          "CAP": "62010",
          "LOCALITA": "PIEDIRIPA",
          "REGIONE": "MC",
          "REGIONNAME": "Macerata",
          "TEL1": "0733281275"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "328609",
          "RAGIONESOCIALE": "MARCHETTI GOMME DI MARCHETTI P. E C",
          "CHANNEL": "P1",
          "VIA": "CONTRADA MOLINO, 62",
          "CAP": "63828",
          "LOCALITA": "CAMPOFILONE",
          "REGIONE": "FM",
          "REGIONNAME": "Fermo",
          "TEL1": "07359587"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197285",
          "RAGIONESOCIALE": "LA PICENA PNEUMATICI S.R.L.",
          "CHANNEL": "B2",
          "VIA": "ZONA INDUSTRIALE MARINO DEL TRONTO",
          "CAP": "63100",
          "LOCALITA": "ASCOLI PICENO",
          "REGIONE": "AP",
          "REGIONNAME": "Ascoli Piceno",
          "TEL1": "0736402110"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197361",
          "RAGIONESOCIALE": "PNEUMATICI CENTRO VASTO SNC",
          "CHANNEL": "B2",
          "VIA": "VIA MARTIRI DELLA LIBERTA  54",
          "CAP": "66054",
          "LOCALITA": "VASTO",
          "REGIONE": "CH",
          "REGIONNAME": "Chieti",
          "TEL1": "0873 365087"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197479",
          "RAGIONESOCIALE": "CORSALINI GOMME SAS",
          "CHANNEL": "B2",
          "VIA": "VIA DELL INDUSTRIA N. 22",
          "CAP": "62017",
          "LOCALITA": "PORTO RECANATI",
          "REGIONE": "MC",
          "REGIONNAME": "Macerata",
          "TEL1": "0717592662"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "239673",
          "RAGIONESOCIALE": "PUNTOGOMME CONTINI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA ADRIATICA,19/21",
          "CAP": "60015",
          "LOCALITA": "FALCONARA MARITTIMA",
          "REGIONE": "AN",
          "REGIONNAME": "Ancona",
          "TEL1": "0719160176"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "257971",
          "RAGIONESOCIALE": "PNEUS RACING SRL",
          "CHANNEL": "B2",
          "VIA": "VIA DIVISIONE ACQUI, 47",
          "CAP": "61121",
          "LOCALITA": "PESARO",
          "REGIONE": "PU",
          "REGIONNAME": "Pesaro Urbino",
          "TEL1": "0721283220"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "272495",
          "RAGIONESOCIALE": "TECNOGOMME DI PIERSIGILLI PAOLO",
          "CHANNEL": "B2",
          "VIA": "VIA DELL ARTIGIANATO, 2",
          "CAP": "60030",
          "LOCALITA": "ROSORA",
          "REGIONE": "AN",
          "REGIONNAME": "Ancona",
          "TEL1": "0731812183"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "273255",
          "RAGIONESOCIALE": "MC GOMME MORICHETTI DANIELE",
          "CHANNEL": "B2",
          "VIA": "VIALE DEL LAVORO ZONA INDUSTRIALE",
          "CAP": "63813",
          "LOCALITA": "MONTE URANO",
          "REGIONE": "FM",
          "REGIONNAME": "Fermo",
          "TEL1": "0734843441"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "279500",
          "RAGIONESOCIALE": "REDA GOMME DI REDA GIAN MARIO",
          "CHANNEL": "B2",
          "VIA": "VIA PETRARA",
          "CAP": "62024",
          "LOCALITA": "MATELICA",
          "REGIONE": "MC",
          "REGIONNAME": "Macerata",
          "TEL1": "073783111"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197607",
          "RAGIONESOCIALE": "BONI GOMME S.R.L.",
          "CHANNEL": "B2",
          "VIA": "VIA L. GHIBERTI 1/A",
          "CAP": "51100",
          "LOCALITA": "PISTOIA",
          "REGIONE": "PT",
          "REGIONNAME": "Pistoia",
          "TEL1": "057331245"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "277604",
          "RAGIONESOCIALE": "AUTOFFICINA BERNARDINI D. & C. SNC",
          "CHANNEL": "B2",
          "VIA": "VIA M. LUZZI, 1",
          "CAP": "59100",
          "LOCALITA": "PRATO",
          "REGIONE": "PO",
          "REGIONNAME": "Prato",
          "TEL1": "0574622198"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197352",
          "RAGIONESOCIALE": "BONINU ROBERTO",
          "CHANNEL": "B2",
          "VIA": "VIA MAGELLANO S.N.",
          "CAP": "08100",
          "LOCALITA": "NUORO",
          "REGIONE": "NU",
          "REGIONNAME": "Nuoro",
          "TEL1": "0784260007"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "245015",
          "RAGIONESOCIALE": "S.R.S. GOMME DI SANDRO SPIGA",
          "CHANNEL": "B2",
          "VIA": "VIA ABRUZZI, 27",
          "CAP": "09122",
          "LOCALITA": "CAGLIARI",
          "REGIONE": "CA",
          "REGIONNAME": "Cagliari",
          "TEL1": "070272640"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197008",
          "RAGIONESOCIALE": "CENTRAL GOMME CESENA SNC",
          "CHANNEL": "B2",
          "VIA": "VIA EMILIA LEVANTE 1090",
          "CAP": "47521",
          "LOCALITA": "CESENA",
          "REGIONE": "FC",
          "REGIONNAME": "Forlì Cesena",
          "TEL1": "0547300563"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197129",
          "RAGIONESOCIALE": "MIMMI SNC DI L.MIMMI & C.",
          "CHANNEL": "B2",
          "VIA": "VIA NAZIONALE 64",
          "CAP": "40067",
          "LOCALITA": "RASTIGNANO",
          "REGIONE": "BO",
          "REGIONNAME": "Bologna",
          "TEL1": "051743602"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197185",
          "RAGIONESOCIALE": "PNEUS RIMINI DI AMADUZZI BERTO",
          "CHANNEL": "B2",
          "VIA": "VIA XXIII SETTEMBRE 117",
          "CAP": "47900",
          "LOCALITA": "RIMINI",
          "REGIONE": "RN",
          "REGIONNAME": "Rimini",
          "TEL1": "0541742336"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197359",
          "RAGIONESOCIALE": "PALAZZI REMO SRL",
          "CHANNEL": "B2",
          "VIA": "VIA CORTICELLA 193",
          "CAP": "40128",
          "LOCALITA": "BOLOGNA",
          "REGIONE": "BO",
          "REGIONNAME": "Bologna",
          "TEL1": "051320578"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197407",
          "RAGIONESOCIALE": "PAGANINI DIEGO",
          "CHANNEL": "B2",
          "VIA": "LOCALITA  GHERARDI CENTRO N. 91/A",
          "CAP": "44037",
          "LOCALITA": "JOLANDA DI SAVOIA",
          "REGIONE": "FE",
          "REGIONNAME": "Ferrara",
          "TEL1": "0532837505"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "281503",
          "RAGIONESOCIALE": "MONDO GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "STRADA CARDIO, 20",
          "CAP": "47899",
          "LOCALITA": "SERRAVALLE - REPUBBLICA SAN MARINO",
          "TEL1": "0549900477"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "379904",
          "RAGIONESOCIALE": "GOMMISTA B&B DI BUDA VILLIAM",
          "CHANNEL": "B2",
          "VIA": "VIA 1 MAGGIO, 4/A",
          "CAP": "47035",
          "LOCALITA": "GAMBETTOLA",
          "REGIONE": "FC",
          "REGIONNAME": "Forlì Cesena",
          "TEL1": "054758612"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "196971",
          "RAGIONESOCIALE": "BELLI PNEUMATICI SNC DI MAMMI G.& C",
          "CHANNEL": "P1",
          "VIA": "VIA CANALE 218",
          "CAP": "42013",
          "LOCALITA": "S.ANTONINO CASALGRANDE",
          "REGIONE": "RE",
          "REGIONNAME": "Reggio Emilia",
          "TEL1": "0536824178"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "287081",
          "RAGIONESOCIALE": "CASA DEL PNEUMATICO DI FANTUZZI W.&",
          "CHANNEL": "P1",
          "VIA": "VIA A. VOLTA, 8",
          "CAP": "42027",
          "LOCALITA": "MONTECCHIO EMILIA",
          "REGIONE": "RE",
          "REGIONNAME": "Reggio Emilia",
          "TEL1": "0522864655"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "332844",
          "RAGIONESOCIALE": "ZB GOMME SNC",
          "CHANNEL": "P1",
          "VIA": "VIA BENASSI, 7",
          "CAP": "41122",
          "LOCALITA": "MODENA",
          "REGIONE": "MO",
          "REGIONNAME": "Modena",
          "TEL1": "059310180"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "361357",
          "RAGIONESOCIALE": "EUROGOMME 2000 DI ROSSI A. & C. SAS",
          "CHANNEL": "P1",
          "VIA": "STRADA BAGANZOLA, 9/A",
          "CAP": "43126",
          "LOCALITA": "PARMA",
          "REGIONE": "PR",
          "REGIONNAME": "Parma",
          "TEL1": "0521993676"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "263455",
          "RAGIONESOCIALE": "UNIVERSAL PNEUS SRL",
          "CHANNEL": "B2",
          "VIA": "VIA G. MICHELI, 2",
          "CAP": "43056",
          "LOCALITA": "TORRILE",
          "REGIONE": "PR",
          "REGIONNAME": "Parma",
          "TEL1": "0521-819537"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "278368",
          "RAGIONESOCIALE": "GIORDANI SRL CON SOCIO UNICO",
          "CHANNEL": "B2",
          "VIA": "VIA NADI, 1/A",
          "CAP": "43122",
          "LOCALITA": "PARMA",
          "REGIONE": "PR",
          "REGIONNAME": "Parma",
          "TEL1": "0521785803"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "283153",
          "RAGIONESOCIALE": "FRANCO PNEUMATICI DI ZONI EMANUELE",
          "CHANNEL": "B2",
          "VIA": "VIA VERDI, 33",
          "CAP": "43017",
          "LOCALITA": "SAN SECONDO PARMENSE",
          "REGIONE": "PR",
          "REGIONNAME": "Parma",
          "TEL1": "0521872365"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197113",
          "RAGIONESOCIALE": "LUIGI MOMESSO SRL",
          "CHANNEL": "P1",
          "VIA": "VIA COMUNALE 21",
          "CAP": "31046",
          "LOCALITA": "FAE' DI ODERZO",
          "REGIONE": "TV",
          "REGIONNAME": "Treviso",
          "TEL1": "0422853911"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197206",
          "RAGIONESOCIALE": "RIGOMMA SRL",
          "CHANNEL": "P1",
          "VIA": "VIA F.LLI BANDIERA 31/33",
          "CAP": "30175",
          "LOCALITA": "MARGHERA",
          "REGIONE": "VE",
          "REGIONNAME": "Venezia",
          "TEL1": "0415380644"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197212",
          "RAGIONESOCIALE": "SACCON GOMME SPA",
          "CHANNEL": "P1",
          "VIA": "VIA IV NOVEMBRE, 95",
          "CAP": "31010",
          "LOCALITA": "PONTE DELLA PRIULIA",
          "REGIONE": "TV",
          "REGIONNAME": "Treviso",
          "TEL1": "0438758521"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "198753",
          "RAGIONESOCIALE": "RIGOMMA NOALE SRL",
          "CHANNEL": "P1",
          "VIA": "VIA COPPADORO, 1/3",
          "CAP": "30033",
          "LOCALITA": "NOALE",
          "REGIONE": "VE",
          "REGIONNAME": "Venezia"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "198757",
          "RAGIONESOCIALE": "RIGOMMA BUJA SRL",
          "CHANNEL": "P1",
          "VIA": "VIA EUROPA, 2",
          "CAP": "33030",
          "LOCALITA": "BUJA",
          "REGIONE": "UD",
          "REGIONNAME": "Udine",
          "TEL1": "432974146"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "211485",
          "RAGIONESOCIALE": "RIGOMMA BRENTA SRL",
          "CHANNEL": "P1",
          "VIA": "VIALE EUROPA 40/A",
          "CAP": "35010",
          "LOCALITA": "CARMIGNANO DI BRENTA",
          "REGIONE": "PD",
          "REGIONNAME": "Padova"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "257379",
          "RAGIONESOCIALE": "RIGOMMA CORDIGNANO SRL",
          "CHANNEL": "P1",
          "VIA": "VIA STRADA MAESTRA D   ITALIA, 9",
          "CAP": "31016",
          "LOCALITA": "CORDIGNANO",
          "REGIONE": "TV",
          "REGIONNAME": "Treviso",
          "TEL1": "0438992058"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "257381",
          "RAGIONESOCIALE": "RIGOMMA PORDENONE SRL",
          "CHANNEL": "P1",
          "VIA": "VIALE VENEZIA, 71",
          "CAP": "33170",
          "LOCALITA": "PORDENONE",
          "REGIONE": "PN",
          "REGIONNAME": "Pordenone",
          "TEL1": "434364471"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "280115",
          "RAGIONESOCIALE": "RIGOMMA MILANO",
          "CHANNEL": "P1",
          "VIA": "VIA DEL LAVORO, 16",
          "CAP": "20090",
          "LOCALITA": "OPERA",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "257606346"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "295663",
          "RAGIONESOCIALE": "RIGOMMA BRESCIA SRL",
          "CHANNEL": "P1",
          "VIA": "VIA DALMAZIA, 96",
          "CAP": "25125",
          "LOCALITA": "BRESCIA",
          "REGIONE": "BS",
          "REGIONNAME": "Brescia",
          "TEL1": "303531501"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "309304",
          "RAGIONESOCIALE": "RIGOMMA RAVENNA SRL",
          "CHANNEL": "P1",
          "VIA": "VIA A.GRANDI, 17",
          "CAP": "48124",
          "LOCALITA": "RAVENNA",
          "REGIONE": "RA",
          "REGIONNAME": "Ravenna",
          "TEL1": "544684087"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197290",
          "RAGIONESOCIALE": "PUSNAR GOMME DI PUSNAR B.",
          "CHANNEL": "B2",
          "VIA": "VIA GORIZIA 108",
          "CAP": "34070",
          "LOCALITA": "FARRA D'ISONZO",
          "REGIONE": "GO",
          "REGIONNAME": "Gorizia",
          "TEL1": "0481888041"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "211669",
          "RAGIONESOCIALE": "BONETTO SRL",
          "CHANNEL": "B2",
          "VIA": "VIA DEI CHIVE 5",
          "CAP": "31044",
          "LOCALITA": "MONTEBELLUNA",
          "REGIONE": "TV",
          "REGIONNAME": "Treviso",
          "TEL1": "0423609990"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "225172",
          "RAGIONESOCIALE": "GP GOMME S.R.L.",
          "CHANNEL": "B2",
          "VIA": "VIA CABOTO, 20",
          "CAP": "34148",
          "LOCALITA": "TRIESTE",
          "REGIONE": "TS",
          "REGIONNAME": "Trieste",
          "TEL1": "040826773"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "271841",
          "RAGIONESOCIALE": "LIVENZA GOMME",
          "CHANNEL": "B2",
          "VIA": "VIA BENEDETTI, 30",
          "CAP": "31018",
          "LOCALITA": "FRANCENIGO DI GAIARINE",
          "REGIONE": "TV",
          "REGIONNAME": "Treviso",
          "TEL1": "043476322"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "300499",
          "RAGIONESOCIALE": "FINOTTO GOMME SNC DI FINOTTO E. & C",
          "CHANNEL": "B2",
          "VIA": "VIA UNITA  D ITALIA, 19",
          "CAP": "30027",
          "LOCALITA": "SAN DONA' DI PIAVE",
          "REGIONE": "VE",
          "REGIONNAME": "Venice",
          "TEL1": "042152063"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "314358",
          "RAGIONESOCIALE": "PNEUS MELLAREDO",
          "CHANNEL": "B2",
          "VIA": "VIA TEVERE, 5",
          "CAP": "30030",
          "LOCALITA": "PIANIGA",
          "REGIONE": "VE",
          "REGIONNAME": "Venice",
          "TEL1": "0415190150"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "359578",
          "RAGIONESOCIALE": "CECCHINATO PNEUMATICI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA DOBBIACO, 4",
          "CAP": "30174",
          "LOCALITA": "Mestre",
          "REGIONE": "VE",
          "REGIONNAME": "Venice",
          "TEL1": "0415343565"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197120",
          "RAGIONESOCIALE": "SOC.MARINA S.N.C",
          "CHANNEL": "P1",
          "VIA": "VIA C. BATTISTI N.201",
          "CAP": "35031",
          "LOCALITA": "ABANO TERME",
          "REGIONE": "PD",
          "REGIONNAME": "Padova",
          "TEL1": "049811215"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197570",
          "RAGIONESOCIALE": "DANIELI GOMME DI DANIELI NICOLA",
          "CHANNEL": "P1",
          "VIA": "VIA PONZIMIGLIO, 2/B",
          "CAP": "36047",
          "LOCALITA": "MONTEGALDA",
          "REGIONE": "VI",
          "REGIONNAME": "Vicenza",
          "TEL1": "0444636259"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "200949",
          "RAGIONESOCIALE": "ZEN GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA DELLA VITTORIA, 2",
          "CAP": "36065",
          "LOCALITA": "MUSSOLENTE",
          "REGIONE": "VI",
          "REGIONNAME": "Vicenza",
          "TEL1": "0424511087"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "215239",
          "RAGIONESOCIALE": "PARISE GOMME SNC",
          "CHANNEL": "P1",
          "VIA": "VIA A. MORO,5",
          "CAP": "36028",
          "LOCALITA": "ROSSANO VENETO",
          "REGIONE": "VI",
          "REGIONNAME": "Vicenza",
          "TEL1": "0424540021"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "337504",
          "RAGIONESOCIALE": "RIVA GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA DEL PROGRESSO, 1",
          "CAP": "36050",
          "LOCALITA": "SOVIZZO",
          "REGIONE": "VI",
          "REGIONNAME": "Vicenza",
          "TEL1": "0444376300"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197558",
          "RAGIONESOCIALE": "FERRO AMPELIO & C. SNC",
          "CHANNEL": "B2",
          "VIA": "VIA CARDUCCI 27/B - LEVADA",
          "CAP": "35017",
          "LOCALITA": "PIOMBINO DESE",
          "REGIONE": "PD",
          "REGIONNAME": "Padova",
          "TEL1": "0499350033"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "280009",
          "RAGIONESOCIALE": "BRUSAMARELLO GOMME THIENE di M.& C.",
          "CHANNEL": "B2",
          "VIA": "VIA MASERE, 1",
          "CAP": "36016",
          "LOCALITA": "THIENE",
          "REGIONE": "VI",
          "REGIONNAME": "Vicenza",
          "TEL1": "0445366373"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "314320",
          "RAGIONESOCIALE": "BRUSAMARELLO PADOVA SRL",
          "CHANNEL": "B2",
          "VIA": "VIA UNITA  D ITALIA, 19",
          "CAP": "35010",
          "LOCALITA": "LIMENA",
          "REGIONE": "PD",
          "REGIONNAME": "Padova",
          "TEL1": "0445670650"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "328966",
          "RAGIONESOCIALE": "MONTEBELLO GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "STRADA STATALE 11 CA SORDIS, 12",
          "CAP": "36054",
          "LOCALITA": "MONTEBELLO VICENTINO",
          "REGIONE": "VI",
          "REGIONNAME": "Vicenza",
          "TEL1": "044464526"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "337068",
          "RAGIONESOCIALE": "MORO'S GARAGE DI MORO MARCO",
          "CHANNEL": "B2",
          "VIA": "VIA GALILEO GALILEI, 7",
          "CAP": "35020",
          "LOCALITA": "LEGNARO",
          "REGIONE": "PD",
          "REGIONNAME": "Padova",
          "TEL1": "049641333"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197105",
          "RAGIONESOCIALE": "LAZZARINI PNEUSERVICE SRL",
          "CHANNEL": "P1",
          "VIA": "VIA GERMANIA, 10",
          "CAP": "37136",
          "LOCALITA": "VERONA",
          "REGIONE": "VR",
          "REGIONNAME": "Verona",
          "TEL1": "045504472"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197216",
          "RAGIONESOCIALE": "SAVOIA PNEUMATICI SRL",
          "CHANNEL": "P1",
          "VIA": "VIA MATTEOTTI 12",
          "CAP": "37010",
          "LOCALITA": "S.AMBROGIO VALPO.LLA",
          "REGIONE": "VR",
          "REGIONNAME": "Verona",
          "TEL1": "0456861158"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "290885",
          "RAGIONESOCIALE": "FANELLI GOMME DI FANELLI PIERLUIGI",
          "CHANNEL": "P1",
          "VIA": "VIA LECHI, 8",
          "CAP": "25012",
          "LOCALITA": "CALVISANO",
          "REGIONE": "BS",
          "REGIONNAME": "Brescia",
          "TEL1": "030968265"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "337975",
          "RAGIONESOCIALE": "P & P GOMME DI POZZANI CRISTIAN SNC",
          "CHANNEL": "P1",
          "VIA": "VIALE EUROPA, 28",
          "CAP": "37045",
          "LOCALITA": "LEGNAGO",
          "REGIONE": "VR",
          "REGIONNAME": "Verona",
          "TEL1": "0442629161"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "254345",
          "RAGIONESOCIALE": "INDUSTRIAL GOMME GROUP SRL",
          "CHANNEL": "B2",
          "VIA": "VIA BENACO, 167",
          "CAP": "25081",
          "LOCALITA": "BEDIZZOLE",
          "REGIONE": "BS",
          "REGIONNAME": "Brescia",
          "TEL1": "0306871580"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "347651",
          "RAGIONESOCIALE": "GOG GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA SERENISSIMA, 3",
          "CAP": "25135",
          "LOCALITA": "BRESCIA",
          "REGIONE": "BS",
          "REGIONNAME": "Brescia",
          "TEL1": "0302350115"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "383152",
          "RAGIONESOCIALE": "MAESTRELLI GOMME SAS",
          "CHANNEL": "B2",
          "VIA": "VIA G. CARDUCCI, 17/F",
          "CAP": "25126",
          "LOCALITA": "BRESCIA",
          "REGIONE": "BS",
          "REGIONNAME": "Brescia",
          "TEL1": "030318365"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197530",
          "RAGIONESOCIALE": "GIUDICI GOMME DI GIUDICI G.& C.SNC",
          "CHANNEL": "P1",
          "VIA": "VIA CIOIA DI MONZONE 4/4 BIS",
          "CAP": "28845",
          "LOCALITA": "DOMODOSSOLA",
          "REGIONE": "VB",
          "REGIONNAME": "Verbania",
          "TEL1": "0324240353"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197534",
          "RAGIONESOCIALE": "NUOVA ORRIGONI GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA L.GIAMPAOLO, 2",
          "CAP": "21100",
          "LOCALITA": "VARESE",
          "REGIONE": "VA",
          "REGIONNAME": "Varese",
          "TEL1": "0332335140"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "254551",
          "RAGIONESOCIALE": "FST SRL",
          "CHANNEL": "P1",
          "VIA": "VIA FRATELLI KENNEDY, 8",
          "CAP": "21040",
          "LOCALITA": "VENEGONO INFERIORE",
          "REGIONE": "VA",
          "REGIONNAME": "Varese",
          "TEL1": "0331869882"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "334267",
          "RAGIONESOCIALE": "PIT STOP SERVICE SRL",
          "CHANNEL": "P1",
          "VIA": "VIA GRIFFI, 6",
          "CAP": "21100",
          "LOCALITA": "VARESE",
          "REGIONE": "VA",
          "REGIONNAME": "Varese",
          "TEL1": "0331073777"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "362085",
          "RAGIONESOCIALE": "SEGRATE GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA MODIGLIANI ANG. VIA MORANDI",
          "CAP": "20090",
          "LOCALITA": "SEGRATE",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "022137770"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197309",
          "RAGIONESOCIALE": "PICASSO GOMME SAVONA SRL",
          "CHANNEL": "P1",
          "VIA": "VIA TRIESTE, 10/12/14",
          "CAP": "17047",
          "LOCALITA": "VADO LIGURE",
          "REGIONE": "SV",
          "REGIONNAME": "Savona",
          "TEL1": "0192162110"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197349",
          "RAGIONESOCIALE": "CANDELA GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA CUNEO 96",
          "CAP": "12011",
          "LOCALITA": "BORGO SAN DALMAZZO",
          "REGIONE": "CN",
          "REGIONNAME": "Cuneo",
          "TEL1": "0171261679"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "297855",
          "RAGIONESOCIALE": "NOVAGOM DI INCARDONA NICOLO' CATENO",
          "CHANNEL": "P1",
          "VIA": "VIALE ITALIA, 64/B",
          "CAP": "14053",
          "LOCALITA": "CANELLI",
          "REGIONE": "AT",
          "REGIONNAME": "Asti",
          "TEL1": "0141824136"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "247716",
          "RAGIONESOCIALE": "LANGA PNEUS SRL",
          "CHANNEL": "B2",
          "VIA": "VIA AUTOSTRADA, 19",
          "CAP": "12061",
          "LOCALITA": "CARRU'",
          "REGIONE": "CN",
          "REGIONNAME": "Cuneo",
          "TEL1": "0173750770"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "279970",
          "RAGIONESOCIALE": "SPORTLINE PNEUS SRL",
          "CHANNEL": "B2",
          "VIA": "VIA ROBERT SCHUMMAN, 2/A",
          "CAP": "15067",
          "LOCALITA": "NOVI LIGURE",
          "REGIONE": "AL",
          "REGIONNAME": "Alessandria",
          "TEL1": "014373870"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "280854",
          "RAGIONESOCIALE": "NASTO PNEUMATICI",
          "CHANNEL": "B2",
          "VIA": "VIA CIRCONVALAZIONE, 9",
          "CAP": "12030",
          "LOCALITA": "SCARNAFIGI",
          "REGIONE": "CN",
          "REGIONNAME": "Cuneo",
          "TEL1": "017574450"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "289100",
          "RAGIONESOCIALE": "NARDI GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA GIACOMO BOVE, 28 ROSSO",
          "CAP": "17100",
          "LOCALITA": "SAVONA",
          "REGIONE": "SV",
          "REGIONNAME": "Savona",
          "TEL1": "0192302195"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197153",
          "RAGIONESOCIALE": "PANSINI GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA COPERCHINI, 9",
          "CAP": "29121",
          "LOCALITA": "PIACENZA",
          "REGIONE": "PC",
          "REGIONNAME": "Piacenza",
          "TEL1": "0523481844"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197658",
          "RAGIONESOCIALE": "ZANCOLLI SPA",
          "CHANNEL": "P1",
          "VIA": "LOCALITA  PRELLI, 52",
          "CAP": "19037",
          "LOCALITA": "SANTO STEFANO DI MAGRA",
          "REGIONE": "SP",
          "REGIONNAME": "La Spezia",
          "TEL1": "0187630210"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "229929",
          "RAGIONESOCIALE": "CAR BIKE GOMME SAS",
          "CHANNEL": "P1",
          "VIA": "VIA MULTEDO DI PEGLI, 2 p.t.",
          "CAP": "16155",
          "LOCALITA": "GENOVA",
          "REGIONE": "GE",
          "REGIONNAME": "Genova",
          "TEL1": "0106671831"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "251461",
          "RAGIONESOCIALE": "SCOTTO PNEUMATICI SNC",
          "CHANNEL": "P1",
          "VIA": "VIA PARMA, 382",
          "CAP": "16043",
          "LOCALITA": "CHIAVARI",
          "REGIONE": "GE",
          "REGIONNAME": "Genova",
          "TEL1": "0185382043"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "270839",
          "RAGIONESOCIALE": "PELLEGRINI GOMME",
          "CHANNEL": "P1",
          "VIA": "VIA AURELIA, 349",
          "CAP": "19020",
          "LOCALITA": "RICCO' DEL GOLFO",
          "REGIONE": "SP",
          "REGIONNAME": "La Spezia",
          "TEL1": "0187925534"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "360851",
          "RAGIONESOCIALE": "BATTISTINI GOMME",
          "CHANNEL": "P1",
          "VIA": "VIA LATIRO, 50",
          "CAP": "16039",
          "LOCALITA": "SESTRI LEVANTE",
          "REGIONE": "GE",
          "REGIONNAME": "Genova",
          "TEL1": "018542266"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "360924",
          "RAGIONESOCIALE": "DG GROUP SRL",
          "CHANNEL": "P1",
          "VIA": "VIA TORTONA, 10",
          "CAP": "27058",
          "LOCALITA": "VOGHERA",
          "REGIONE": "PV",
          "REGIONNAME": "Pavia",
          "TEL1": "0383367134"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197077",
          "RAGIONESOCIALE": "GOMMAUTO SNC DI CESINA CARLO & C.",
          "CHANNEL": "B2",
          "VIA": "VIA EMILIA PARMENSE 144/D",
          "CAP": "29122",
          "LOCALITA": "PIACENZA",
          "REGIONE": "PC",
          "REGIONNAME": "Piacenza",
          "TEL1": "0523593388"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197424",
          "RAGIONESOCIALE": "BARATTI ANGELO",
          "CHANNEL": "B2",
          "VIA": "VIA GORIZIA 56",
          "CAP": "27036",
          "LOCALITA": "MORTARA",
          "REGIONE": "PV",
          "REGIONNAME": "Pavia",
          "TEL1": "038490487"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "296130",
          "RAGIONESOCIALE": "LIGURGOMME DI CORTESE ANTONIO",
          "CHANNEL": "B2",
          "VIA": "VIA PIERAGOSTINI, 51-53",
          "CAP": "16151",
          "LOCALITA": "GENOVA",
          "REGIONE": "GE",
          "REGIONNAME": "Genova",
          "TEL1": "010460663"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "361808",
          "RAGIONESOCIALE": "LERMA GOMME BUSALLA SNC",
          "CHANNEL": "B2",
          "VIA": "VIA GUIDO ROSSA, 17",
          "CAP": "16012",
          "LOCALITA": "BUSALLA",
          "REGIONE": "GE",
          "REGIONNAME": "Genova",
          "TEL1": "0109642504"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "213241",
          "RAGIONESOCIALE": "MOMPIANI GOMME DI MOMPIANI ANDREA",
          "CHANNEL": "B2",
          "VIA": "VIA GALILEI 8",
          "CAP": "13900",
          "LOCALITA": "BIELLA",
          "REGIONE": "BI",
          "REGIONNAME": "Biella",
          "TEL1": "01522393"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "281878",
          "RAGIONESOCIALE": "ERMES GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA CARMAGNOLA, 5",
          "CAP": "10046",
          "LOCALITA": "POIRINO",
          "REGIONE": "TO",
          "REGIONNAME": "Torino",
          "TEL1": "0119450558"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "285685",
          "RAGIONESOCIALE": "BRICHERASIO GOMME SNC",
          "CHANNEL": "B2",
          "VIA": "VIA VALDOMENICA, 17",
          "CAP": "10060",
          "LOCALITA": "BRICHERASIO",
          "REGIONE": "TO",
          "REGIONNAME": "Torino",
          "TEL1": "0121598365"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "289365",
          "RAGIONESOCIALE": "LINEA PNEUS DI FAVANO ANTONINO",
          "CHANNEL": "B2",
          "VIA": "VIA NIZZA, 31",
          "CAP": "10125",
          "LOCALITA": "TORINO",
          "REGIONE": "TO",
          "REGIONNAME": "Torino",
          "TEL1": "0116507361"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "379164",
          "RAGIONESOCIALE": "ROYAL PNEUMATICI SRL",
          "CHANNEL": "P1",
          "VIA": "VIA MARCO D AGRATE, 32",
          "CAP": "20139",
          "LOCALITA": "MILANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "025393454"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197066",
          "RAGIONESOCIALE": "FRIGERIO GOMME DI DOMENICO PELLEGRI",
          "CHANNEL": "B2",
          "VIA": "VIA RUGGERO BOSCOVICH, 53",
          "CAP": "20129",
          "LOCALITA": "MILANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "02 29516202"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197366",
          "RAGIONESOCIALE": "FRIGERIO GOMME SAS DI A. TOMASI & C",
          "CHANNEL": "B2",
          "VIA": "VIA GIORGIO WASHINGTON, 89",
          "CAP": "20146",
          "LOCALITA": "MILANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "024233200"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "223507",
          "RAGIONESOCIALE": "EROS GOMME DI LUNARI EROS",
          "CHANNEL": "B2",
          "VIA": "VIA PO, 79",
          "CAP": "20032",
          "LOCALITA": "CORMANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "02 66307181"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "281567",
          "RAGIONESOCIALE": "A.M.E. SPORT TECNICH SNC",
          "CHANNEL": "B2",
          "VIA": "VIA VOLTRI, 10",
          "CAP": "20142",
          "LOCALITA": "MILANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "028393433"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "310652",
          "RAGIONESOCIALE": "GT GOMME SNC DI CARAMELLINO MARCO &",
          "CHANNEL": "B2",
          "VIA": "VIA G.AMADEO, 85",
          "CAP": "20134",
          "LOCALITA": "MILANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "02 7610764"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "314884",
          "RAGIONESOCIALE": "TARENZI F.LLI SNC",
          "CHANNEL": "B2",
          "VIA": "VIA ROSSIGNOLI, 11",
          "CAP": "26013",
          "LOCALITA": "CREMA",
          "REGIONE": "CR",
          "REGIONNAME": "Cremona",
          "TEL1": "037331030"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "371813",
          "RAGIONESOCIALE": "COLLEVECCHIO GOMME DI COLLEVECCHIO",
          "CHANNEL": "B2",
          "VIA": "VIA GROSSICH, 29",
          "CAP": "20131",
          "LOCALITA": "MILANO",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "022663485"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "239738",
          "RAGIONESOCIALE": "GORGA GOMME DI GORGA NICOLA",
          "CHANNEL": "P1",
          "VIA": "VIA SABIN, 13",
          "CAP": "20040",
          "LOCALITA": "CAVENAGO BRIANZA",
          "REGIONE": "MB",
          "REGIONNAME": "Monza e Brianza",
          "TEL1": "0295339148"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "311954",
          "RAGIONESOCIALE": "COLOMBO GOMME DI COLOMBO A. E C. SN",
          "CHANNEL": "P1",
          "VIA": "VIA GAETANO DONIZETTI, 14",
          "CAP": "20862",
          "LOCALITA": "ARCORE",
          "REGIONE": "MB",
          "REGIONNAME": "Monza e Brianza",
          "TEL1": "0396013205"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "316705",
          "RAGIONESOCIALE": "F.LLI SPINELLI SRL",
          "CHANNEL": "P1",
          "VIA": "VIA NAZIONALE DEI GIOVI, 92",
          "CAP": "20811",
          "LOCALITA": "CESANO MADERNO",
          "REGIONE": "MB",
          "REGIONNAME": "Monza e Brianza",
          "TEL1": "0362503576"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "368132",
          "RAGIONESOCIALE": "COLOMBO SNC DI COLOMBO ALESSANDRO E",
          "CHANNEL": "P1",
          "VIA": "VIALE LOMBARDIA, 15",
          "CAP": "20056",
          "LOCALITA": "TREZZO SULL'ADDA",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "0290964841"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197319",
          "RAGIONESOCIALE": "LONGONI GOMME SNC",
          "CHANNEL": "B2",
          "VIA": "VIA TOFANE 20",
          "CAP": "20833",
          "LOCALITA": "GIUSSANO",
          "REGIONE": "MB",
          "REGIONNAME": "Monza e Brianza",
          "TEL1": "0362850001"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "200135",
          "RAGIONESOCIALE": "PNEUMATICI VALTELLINA S.R.L",
          "CHANNEL": "B2",
          "VIA": "VIA GUICCIARDI 2",
          "CAP": "23020",
          "LOCALITA": "PIATEDA",
          "REGIONE": "SO",
          "REGIONNAME": "Sondrio",
          "TEL1": "0342370650"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "305764",
          "RAGIONESOCIALE": "VIMERCATE GOMME SNC",
          "CHANNEL": "B2",
          "VIA": "VIA LECCO, 71",
          "CAP": "20871",
          "LOCALITA": "VIMERCATE",
          "REGIONE": "MB",
          "REGIONNAME": "Monza e Brianza",
          "TEL1": "039669602"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "313086",
          "RAGIONESOCIALE": "FRANCO GOMME S.N.C.",
          "CHANNEL": "B2",
          "VIA": "VIALE RIMEMBRANZE, 54",
          "CAP": "20099",
          "LOCALITA": "SESTO SAN GIOVANNI",
          "REGIONE": "MI",
          "REGIONNAME": "Milano",
          "TEL1": "0226224322"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "319333",
          "RAGIONESOCIALE": "MERONI SERVICE CAR SAS DI G.MERONI",
          "CHANNEL": "B2",
          "VIA": "VIA GABRIELE D ANNUNZIO, 14",
          "CAP": "20835",
          "LOCALITA": "MUGGIO'",
          "REGIONE": "MB",
          "REGIONNAME": "Monza e Brianza",
          "TEL1": "039793944"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "322441",
          "RAGIONESOCIALE": "ANGELA GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA FERMI, 44",
          "CAP": "24035",
          "LOCALITA": "CURNO",
          "REGIONE": "BG",
          "REGIONNAME": "Bergamo",
          "TEL1": "0354156791"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197480",
          "RAGIONESOCIALE": "MASTRIA GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIALE PIO X 64",
          "CAP": "88100",
          "LOCALITA": "CATANZARO",
          "REGIONE": "CZ",
          "REGIONNAME": "Catanzaro",
          "TEL1": "0961789275"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "251463",
          "RAGIONESOCIALE": "CORASANITI SALVATORE",
          "CHANNEL": "P1",
          "VIA": "VIALE CASSIODORO, 269",
          "CAP": "88060",
          "LOCALITA": "DAVOLI",
          "REGIONE": "CZ",
          "REGIONNAME": "Catanzaro",
          "TEL1": "096771506"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "322408",
          "RAGIONESOCIALE": "SUD GOMME DI FIARE'",
          "CHANNEL": "P1",
          "VIA": "ZONA INDUSTRIALE LOCALITA  AEREOPOR",
          "CAP": "89900",
          "LOCALITA": "VIBO VALENTIA",
          "REGIONE": "VV",
          "REGIONNAME": "Vibo Valentia",
          "TEL1": "0963263332"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "261122",
          "RAGIONESOCIALE": "PAVONE GIOVANNI",
          "CHANNEL": "B2",
          "VIA": "VIA NAZIONALE PENTIMELE, 177",
          "CAP": "89121",
          "LOCALITA": "REGGIO CALABRIA",
          "REGIONE": "RC",
          "REGIONNAME": "Reggio Calabria",
          "TEL1": "096545038"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "287240",
          "RAGIONESOCIALE": "DAG SRL",
          "CHANNEL": "B2",
          "VIA": "CONTRADA PETRARA, 5",
          "CAP": "89020",
          "LOCALITA": "MELICUCCO",
          "REGIONE": "RC",
          "REGIONNAME": "Reggio Calabria",
          "TEL1": "0966506681"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197215",
          "RAGIONESOCIALE": "SATEP SRL",
          "CHANNEL": "P1",
          "VIA": "VIA EMANUELE GIANTURCO 101",
          "CAP": "80143",
          "LOCALITA": "NAPOLI",
          "REGIONE": "NA",
          "REGIONNAME": "Napoli",
          "TEL1": "0817340219"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "200604",
          "RAGIONESOCIALE": "A.R. DI ARCELLA S.R.L.",
          "CHANNEL": "P1",
          "VIA": "VIA GUIDO DE RUGGIERO, 26",
          "CAP": "80128",
          "LOCALITA": "NAPOLI",
          "REGIONE": "NA",
          "REGIONNAME": "Napoli",
          "TEL1": "0815790477"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "348058",
          "RAGIONESOCIALE": "S GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIALE DELL   AERONAUTICA",
          "CAP": "80038",
          "LOCALITA": "POMIGLIANO D'ARCO",
          "REGIONE": "NA",
          "REGIONNAME": "Napoli",
          "TEL1": "0818842552"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "287017",
          "RAGIONESOCIALE": "DS&A PNEUMATICI SAS DI DI SANTO G.",
          "CHANNEL": "B2",
          "VIA": "CONTRADA PEZZAPIANA",
          "CAP": "82100",
          "LOCALITA": "BENEVENTO",
          "REGIONE": "BN",
          "REGIONNAME": "Benevento",
          "TEL1": "03319896250"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "197537",
          "RAGIONESOCIALE": "F.LLI COLASUONNO SNC",
          "CHANNEL": "P1",
          "VIA": "S.P. 231 KM 41+580",
          "CAP": "76123",
          "LOCALITA": "ANDRIA",
          "REGIONE": "BT",
          "REGIONNAME": "Barletta-Andria-Tran",
          "TEL1": "0883565514"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "259392",
          "RAGIONESOCIALE": "PALERMO GOMME SRL",
          "CHANNEL": "P1",
          "VIA": "VIA CARDUCCI, 1",
          "CAP": "70010",
          "LOCALITA": "VALENZANO",
          "REGIONE": "BA",
          "REGIONNAME": "Bari",
          "TEL1": "0804671910"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "278068",
          "RAGIONESOCIALE": "TRIDELCO SNC DI DELL'ERBA E C. SNC",
          "CHANNEL": "B2",
          "VIA": "SP 231 KM 68,200",
          "CAP": "76012",
          "LOCALITA": "CANOSA",
          "REGIONE": "BT",
          "REGIONNAME": "Barletta-Andria-Tran",
          "TEL1": "3342276100"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "329369",
          "RAGIONESOCIALE": "DITTA MILANO OTTAVIO DI MILANO GIOV",
          "CHANNEL": "P1",
          "VIA": "VIA GIOVANNI XXIII, 90",
          "CAP": "70023",
          "LOCALITA": "GIOIA DEL COLLE",
          "REGIONE": "BA",
          "REGIONNAME": "Bari",
          "TEL1": "0803434498"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197503",
          "RAGIONESOCIALE": "DI COSMO GOMME DI SAVERIO DI COSMO",
          "CHANNEL": "B2",
          "VIA": "VIALE DELL ARTIGIANATO, 23 - Z.I.",
          "CAP": "70026",
          "LOCALITA": "MODUGNO",
          "REGIONE": "BA",
          "REGIONNAME": "Bari",
          "TEL1": "0805354818"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "277901",
          "RAGIONESOCIALE": "SUPERGOM DI TAGLIENTE GIUSEPPE PAOL",
          "CHANNEL": "B2",
          "VIA": "VIA ALESSANDRO FIGHERA 96",
          "CAP": "74015",
          "LOCALITA": "MARTINA FRANCA",
          "REGIONE": "TA",
          "REGIONNAME": "Taranto",
          "TEL1": "0804839076"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "310541",
          "RAGIONESOCIALE": "RIVER SRL",
          "CHANNEL": "B2",
          "VIA": "VIA UMBRIA, 157/A",
          "CAP": "74121",
          "LOCALITA": "TARANTO",
          "REGIONE": "TA",
          "REGIONNAME": "Taranto",
          "TEL1": "099 4760933"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "348228",
          "RAGIONESOCIALE": "TOTARO FRANCESCO PNEUMATICI SRL",
          "CHANNEL": "B2",
          "VIA": "VIA LEUCA, 110 /116",
          "CAP": "73020",
          "LOCALITA": "CAVALLINO",
          "REGIONE": "LE",
          "REGIONNAME": "Lecce",
          "TEL1": "0832 345351"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "360048",
          "RAGIONESOCIALE": "PNEURAMA DI GIOIA",
          "CHANNEL": "B2",
          "VIA": "VIA TRANI, 177",
          "CAP": "70033",
          "LOCALITA": "CORATO",
          "REGIONE": "BT",
          "REGIONNAME": "Barletta-Andria-Tran",
          "TEL1": "3396008461"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "231299",
          "RAGIONESOCIALE": "S.G. GOMME SRL",
          "CHANNEL": "B2",
          "VIA": "VIA GIORDANO 49",
          "CAP": "90144",
          "LOCALITA": "PALERMO",
          "REGIONE": "PA",
          "REGIONNAME": "Palermo",
          "TEL1": "0916815100"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "306781",
          "RAGIONESOCIALE": "HENNA PNEUS SRL",
          "CHANNEL": "B2",
          "VIA": "VIA UNITA  D ITALIA, 81",
          "CAP": "94100",
          "LOCALITA": "ENNA",
          "REGIONE": "EN",
          "REGIONNAME": "Enna",
          "TEL1": "093520002"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "328596",
          "RAGIONESOCIALE": "TRUPIANO GOMME",
          "CHANNEL": "B2",
          "VIA": "VIALE REGIONE SICILIANA SUD EST 2097",
          "CAP": "90135",
          "LOCALITA": "PALERMO",
          "REGIONE": "PA",
          "REGIONNAME": "Palermo",
          "TEL1": "091431349"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "311973",
          "RAGIONESOCIALE": "PIT STOP DI RICCI ANTONIO VALENTINO",
          "CHANNEL": "P1",
          "VIA": "CORSO LEONE MUCCI, 157",
          "CAP": "71016",
          "LOCALITA": "SAN SEVERO",
          "REGIONE": "FG",
          "REGIONNAME": "Foggia",
          "TEL1": "0882603698"
      },
      {
          "NETWORK": "BSP",
          "CODICECLIENTE ": "313888",
          "RAGIONESOCIALE": "LOSCO ERNESTO",
          "CHANNEL": "P1",
          "VIA": "VIA CIRCUMVALLAZIONE, 10",
          "CAP": "83042",
          "LOCALITA": "ATRIPALDA",
          "REGIONE": "AV",
          "REGIONNAME": "Avellino",
          "TEL1": "0825626046"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "197272",
          "RAGIONESOCIALE": "R.G.S. PNEUMATICI S.R.L.",
          "CHANNEL": "B2",
          "VIA": "CONTRADA CHIANIZZI - ZONA IND.LE",
          "CAP": "85038",
          "LOCALITA": "SENISE",
          "REGIONE": "PZ",
          "REGIONNAME": "Potenza",
          "TEL1": "0973584318"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "203276",
          "RAGIONESOCIALE": "VALPNEUS SNC DEI F.LLI MOSCARELLA",
          "CHANNEL": "B2",
          "VIA": "VIA PROVINCIALE CONTRADA FIUME",
          "CAP": "84039",
          "LOCALITA": "TEGGIANO",
          "REGIONE": "SA",
          "REGIONNAME": "Salerno",
          "TEL1": "097570485"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "271412",
          "RAGIONESOCIALE": "OK GOMME DI ANTONIO TOTARO",
          "CHANNEL": "B2",
          "VIA": "VIA G. DI VITTORIO, 267/269",
          "CAP": "71043",
          "LOCALITA": "MANFREDONIA",
          "REGIONE": "FG",
          "REGIONNAME": "Foggia",
          "TEL1": "0884543226"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "275663",
          "RAGIONESOCIALE": "ERINNIO MICHELE",
          "CHANNEL": "B2",
          "VIA": "VIALE TERMINILLO, 75/77",
          "CAP": "71042",
          "LOCALITA": "CERIGNOLA",
          "REGIONE": "FG",
          "REGIONNAME": "Foggia",
          "TEL1": "0885420888"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "287602",
          "RAGIONESOCIALE": "P.T. GOMME DI D'URSI E RUSSO SNC",
          "CHANNEL": "B2",
          "VIA": "VIA XXV LUGLIO, 148",
          "CAP": "84013",
          "LOCALITA": "CAVA DE' TIRRENI",
          "REGIONE": "SA",
          "REGIONNAME": "Salerno",
          "TEL1": "0894689366"
      },
      {
          "NETWORK": "FST",
          "CODICECLIENTE ": "333857",
          "RAGIONESOCIALE": "EUROGOMME DI GRECO GIUSEPPE",
          "CHANNEL": "B2",
          "VIA": "CONTRADA VERNETA",
          "CAP": "85042",
          "LOCALITA": "LAGONEGRO",
          "REGIONE": "PZ",
          "REGIONNAME": "Potenza",
          "TEL1": "097340459"
      }
  ]




export default resellersObj;
