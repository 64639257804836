import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Logo from "../assets/img/logo-bridgestone.png";
import LogoWhite from "../assets/img/logo-bridgestone_bianco.png";
import { useScroll } from "../utils/useScroll";

const Header = ({ isRegistration, history }) => {
  const { scrollY } = useScroll();
  const [isLogoWhite, setIsLogoWhite] = useState(false);
  useEffect(() => {
    if (scrollY > 127) {
      setIsLogoWhite(true);
    } else {
      setIsLogoWhite(false);
    }
  });

  const goToHome = () => {
    window.location.href = `${window.location.origin}`;
  };

  return (
    <header>
      <Navbar bg="none" expand="lg" fixed="top">
        <Container fluid={true}>
          <Navbar.Brand onClick={() => goToHome()} aria-label="Logo">
            <img src={isLogoWhite ? LogoWhite : Logo} alt="Logo"></img>
          </Navbar.Brand>

          {!isRegistration && (
            <Fragment>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span />
                <span />
                <span />
              </Navbar.Toggle>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Link
                    href="#"
                    className="nav-link"
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={400}
                  >
                    Home
                  </Link>
                  <Link
                    href="#"
                    className="nav-link"
                    activeClass="active"
                    to="reseller"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={400}
                  >
                    Rivenditori aderenti
                  </Link>
                  <Link
                    href="#"
                    className="nav-link"
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={400}
                  >
                    Contatti
                  </Link>
                  <Link
                    href="#"
                    className="nav-link"
                    activeClass="active"
                    to="rules"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={400}
                  >
                    Regolamento
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Fragment>
          )}
        </Container>
      </Navbar>
    </header>
  );
};

export default withRouter(Header);
