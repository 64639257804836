import React, { Fragment } from "react";
import styled from "styled-components";

const FooterDiv = styled.div`
  background: #009fe3;
  padding: 30px 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  p {
    margin: 0;
  }
`;
const Footer = () => {
  return (
    <FooterDiv className="container-fluid">
      <p>© Copyright Bridgestone. All Rights Reserved</p>
    </FooterDiv>
  );
};

export default Footer;
