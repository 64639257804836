import React, { Fragment } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import pdf from "../assets/files/Regolamento OP Bridgestone_Snowit_Def.pdf";

const RulesTitle = styled.h3`
  font-size: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
  width: 100%;
`;
const RulesFiles = styled.div`
  position: relative;
  float: right;
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  font-size: 15px;
`;
const RulesParagraph = styled.div`
  text-align: left;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;

  .title {
    font-weight: 700;
  }

  .title-paragraph {
    margin-bottom: 0;
  }
`;

const Rules = () => {
  return (
    <section id="rules">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <RulesTitle>
              Operazione a premi valida dal 16/12/2019 al 15/04/2020.
            </RulesTitle>
            <RulesFiles>
              <a className="text-danger" href={pdf} target="_blank">
                REGOLAMENTO <FontAwesomeIcon icon={faArrowAltCircleDown} />
              </a>
            </RulesFiles>
            <RulesParagraph>
              <ul>
                <li>
                  Acquista pneumatici invernali Bridgestone/Firestone entro il
                  31/01/2020 e registrati entro il 15/02/2020 per ricevere un
                  voucher Snowit Experience di un valore massimo di 50€.
                </li>
                <li>
                  Prenota con un acconto i pneumatici estivi
                  Bridgestone/Firestone entro il 31/01/2020, completa il tuo
                  acquisto tra il 01/02/2020 e il 15/04/2020 e registrati entro
                  il 22/04/2020 per ricevere un voucher Snowit Experience di un
                  valore massimo di 50€.
                </li>
              </ul>
            </RulesParagraph>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rules;
