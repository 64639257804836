import React, { useState, Fragment } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import {
  GoogleMapProvider,
  MapBox,
  Marker,
  InfoWindow
} from "@googlemap-react/core";
import resellersObj from "../assets/resellers/resellers";
import cities from "../assets/utils/cities";
import Geocode from "react-geocode";

const ResellerTitle = styled.h3`
  font-size: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
  width: 100%;
`;

const SubTitle = styled.p`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #000000;
`;

const Img = styled.img`
  width: 100px;
  height: auto;
`;
const SearchForm = styled.div`
  position: relative;
  float: left;
  text-align: center;
  width: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  height: 500px;
`;

const resellerBox = (item, index) => {
  return (
    <Card className="text-left mb-2" key={index}>
      <Card.Body>
        <Card.Title>
          {index} - {item.RAGIONESOCIALE}
        </Card.Title>
        <Card.Text className="mb-0">{item.VIA}</Card.Text>
        <Card.Text className="mb-0">
          {item.CAP} {item.LOCALITA}
        </Card.Text>
        <Card.Text className="text-danger mb-0">{item.TEL1}</Card.Text>
        {/*<Card.Text className="mb-0">
          <a className="text-danger" href={`mailto: ${item.field13}`}>
            {item.field13}
          </a>
  </Card.Text>*/}
        {/*<Img className="mb-0" src={fstLogo}></Img>*/}
      </Card.Body>
    </Card>
  );
};

const createMarker = (coord, index) => {
  console.log("createMarker", coord, resellersObj, index);
  const markerId = `marker${index}`;
  const infoId = `infoWindow${index}`;
  return (
    <Fragment>
      <Marker
        id={markerId}
        opts={{
          draggable: false,
          label: `${index + 1}`,
          position: coord
        }}
      />
      <InfoWindow
        anchorId={markerId}
        opts={{
          content: `${resellersObj[index].field1}`
        }}
      />
    </Fragment>
  );
};

const Reseller = () => {
  const [values, setValues] = useState({
    search: "",
    regioni: cities.regioni,
    regioneSelected: "",
    provinciaSelected: "-Seleziona-",
    province: [],
    coords: []
  });

  const [resellers, setResellers] = useState([]);

  const googleKey = "AIzaSyC6_luFroQneCjiC_W5z7EIB2BG6Umo_Js"; //"AIzaSyDXZb-SknAws4k7ydwdmINI6BSxhAUWldo";

  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey(googleKey);

  // set response language. Defaults to english.
  Geocode.setLanguage("it");

  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("it");

  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  const handleRegioneChange = e => {
    const value = e.target.value;
    const province = values.regioni.filter(
      regione => regione.nome.toLowerCase() === value.toLowerCase()
    );

    setValues({
      ...values,
      regioneSelected: value,
      province: province && province.length > 0 ? province[0].capoluoghi : [],
      provinciaSelected: "-Seleziona-"
    });
  };

  const handleProvinciaChange = e => {
    const value = e.target.value;
    console.log(value.toLowerCase());
    console.log(resellersObj);
    const resellers = resellersObj.filter(
      reseller =>
        reseller["REGIONNAME"] &&
        reseller["REGIONNAME"].toLowerCase() === value.toLowerCase()
    );
    setValues({ ...values, provinciaSelected: value });
    setResellers(resellers);

    const addresses = [];
    resellers.forEach(reseller => {
      const { VIA, LOCALITA, REGIONNAME } = reseller;
      const address = `${VIA}, ${LOCALITA}, ${REGIONNAME}`;
      addresses.push(address);
    });

    getMapsCoords(addresses);
  };

  const getMapsCoords = addresses => {
    const promises = [];
    const coords = [];
    addresses.forEach(address => {
      promises.push(Geocode.fromAddress(address));
    });

    Promise.all(promises).then(res => {
      res.forEach(address => {
        const coordLatLng = {
          lat: address.results[0].geometry.location.lat,
          lng: address.results[0].geometry.location.lng
        };
        coords.push(coordLatLng);
      });

      setValues({ ...values, coords: coords });
    });
  };

  return (
    <section id="reseller">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ResellerTitle>Rivenditori</ResellerTitle>
            <SubTitle>
              Trova il rivenditore più vicino a te. Seleziona la regione e la
              provincia a te più comoda per scoprire i Punti Vendita aderenti
              all'iniziativa.
            </SubTitle>
          </div>
          <div className="col-12">
            <SearchForm>
              <InputGroup className="mb-3">
                <select
                  className="form-control"
                  value={values.regioneSelected}
                  onChange={handleRegioneChange}
                >
                  <option>-Seleziona-</option>
                  {values.regioni.map((regione, index) => (
                    <option value={regione.nome} key={index}>
                      {regione.nome}
                    </option>
                  ))}
                </select>
              </InputGroup>
              <InputGroup className="mb-3">
                {values.province && (
                  <select
                    className="form-control"
                    value={values.provinciaSelected}
                    onChange={handleProvinciaChange}
                  >
                    <option>-Seleziona-</option>
                    {values.province.map((prov, index) => (
                      <option value={prov} key={index}>
                        {prov}
                      </option>
                    ))}
                  </select>
                )}
              </InputGroup>
            </SearchForm>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-xs-12 col-sm-12 col-md-6">
                {resellers &&
                  resellers.map((reseller, index) =>
                    resellerBox(reseller, index + 1)
                  )}
              </div>
              <div className="col-12 col-xs-12 col-sm-12 col-md-6">
                <MapContainer>
                  {values.coords && values.coords.length > 0 && (
                    <GoogleMapProvider>
                      <MapBox
                        apiKey={googleKey}
                        opts={{
                          center: values.coords[0],
                          zoom: values.coords.length > 1 ? 8 : 10
                        }}
                        useDrawing
                        useGeometry
                        usePlaces
                        useVisualization
                        onCenterChanged={() => {
                          console.log("The center of the map has changed.");
                        }}
                      />
                      {values.coords.map((coord, index) =>
                        createMarker(coord, index)
                      )}
                    </GoogleMapProvider>
                  )}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reseller;
