import React, { Fragment } from "react";
import Header from "./Header";
import Registration from "./Registration";
import Footer from "./Footer";

const RegistrationContainer = () => {
  return (
    <Fragment>
      <Header isRegistration={true}></Header>
      <main>
        <Registration></Registration>
        <Footer></Footer>
      </main>
    </Fragment>
  );
};

export default RegistrationContainer;
