import axios from "axios";

/**
 * Get Token
 */
export function getToken() {
  axios
    .post(
      "https://us-central1-bridgestone-landing-staging.cloudfunctions.net/getTokenSF"
    )
    .then(function(response) {
      console.log(response);
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function saveForm(url, token, data) {
  return axios.post(
    "https://us-central1-bridgestone-landing-staging.cloudfunctions.net/setForms",
    { url, token, data }
  );
}

export function sendEmail(body) {
  axios
    .post(
      "https://us-central1-bridgestone-landing-staging.cloudfunctions.net/emailMessage",
      body
    )
    .then(function(response) {
      console.log(response);
    })
    .catch(function(error) {
      console.log(error);
    });
}

export function getCoords(address) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDXZb-SknAws4k7ydwdmINI6BSxhAUWldo`;
  return axios.get(url);
}
